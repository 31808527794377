import React from "react";

import List from "cms-toolbox/List";

import ListContent from "cms-toolbox/ListContent";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { TopToolbar } from "react-admin";

//import { user as schema } from "taxi-schema";

const schema = [
    {
        source: "path",
        type: "text"
    },
    {
        source: "regPath",
        type: "entity_links",
        label: "Path ID"
    },

    {
        source: "source",
        type: "entity_links",
        label: "Source"
    }
];

const Empty = () => {
    return (
        <Box textAlign="center" m={3}>
            <Typography variant="h4" paragraph>
                Well done!
            </Typography>
            <Typography variant="body1">No broken links found.</Typography>

            <div
                style={{
                    width: "100%",
                    maxWidth: "500px",
                    margin: "30px auto 0"
                }}
            >
                <div
                    style={{
                        paddingBottom: "85%",
                        position: "relative"
                    }}
                >
                    <img
                        src="/illustrations/bravo-A+.png"
                        style={{
                            position: "absolute",
                            top: "32%",
                            left: "37%",
                            width: "56%",
                            filter: "hue-rotate(148deg)"
                        }}
                    />
                    <img
                        src="/illustrations/bravo-robot.png"
                        style={{
                            position: "absolute",
                            top: "1%",
                            left: "5%",
                            width: "48%"
                        }}
                    />
                </div>
            </div>
        </Box>
    );
};

const ListActions = props => {
    const { className } = props;

    return <TopToolbar className={className} />;
};

export class BrokenLinksList extends React.Component {
    render = () => {
        return (
            <List
                title="Broken links"
                {...this.props}
                perPage={1000}
                pagination={null}
                bulkActionButtons={false}
                empty={<Empty />}
                actions={<ListActions />}
            >
                <ListContent
                    {...this.props}
                    schema={schema}
                    showFields={schema.map(field => ({
                        ...field,
                        media: "small"
                    }))}
                    //isTree
                />
            </List>
        );
    };
}
