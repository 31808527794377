import React, { createElement } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import {
    MenuItemLink,
    getResources,
    Responsive,
    setSidebarVisibility,
    setAutomaticRefresh
} from "react-admin";
import { withRouter } from "react-router-dom";


import SvgIcon from "@material-ui/core/SvgIcon";
import withWidth from "@material-ui/core/withWidth";
import { ReactComponent as RevisionIcon } from "icons/resource/revision.svg";

import { ReactComponent as ManualIcon } from "icons/resource/manual.svg";

import {getPermissions} from "cms-config/authProvider"

const menuIcon = icon => {
    return <SvgIcon className="menu-icon">{createElement(icon)}</SvgIcon>;
};

const buildTree = (items, parentName) => {
    const nodes = [];
    for (const item of items) {
        const matchName = item.options?.parent;
        if (matchName === parentName) {
            item.children = buildTree(items, item.name);
            nodes.push(item);
        }
    }
    return nodes;
};

const NoProps = ({ children }) => children;

const Menu = ({ resources, onMenuClick, logout, location, width }) => {

    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
   
   
    const dispatch = useDispatch();
    React.useEffect(() => {
        const mustBeOpen = !~["xs", "sm"].indexOf(width);

        if (mustBeOpen !== open) {
            dispatch(setSidebarVisibility(mustBeOpen));
        }
    }, [width]); //DO NOT REMOVE DEPENDENCY. IF REMOVED, MANUAL MENU TOGGLE DOES NOT WORK

    React.useEffect(() => {
        /*
        disable React-Admin's default automatic refreshing. This refreshes idle tabs after 5 minutes of no interaction. But incorrectly
        refreshed active form values
     */
        dispatch(setAutomaticRefresh(false));
    }, []);

    const menuItemProps = {
        activeClassName: "active-menu-item",
        className: "menu-item",
        onClick: onMenuClick,
        sidebarIsOpen: true
    };
    const { pathname } = location;

    const menuTree = buildTree(resources);

    const {fullAccess}=getPermissions();


    const buildMenu = tree =>
        tree.map(resource => {
            if(!resource.hasList){
                return null
            }
            const children = resource.children;
            const baseTo = `/${resource.name}`;
            const text =
                (resource.options && resource.options.label) || resource.name;

                const leftIcon=(resource.icon ? (
                                menuIcon(resource.icon)
                            ) : (
                                <NoProps>
                                    {!open ? (
                                        <div className="MuiSvgIcon-root side-menu-letter-icon">
                                            {text[0]}
                                        </div>
                                    ) : (
                                        <div />
                                    )}
                                </NoProps>
                            ))
          

            return (
                <React.Fragment key={resource.name}>
                    <MenuItemLink
                        {...menuItemProps}
                        to={baseTo}
                        primaryText={text}
                        leftIcon={
                           resource.options&&resource.options.iconWrap?createElement(resource.options.iconWrap,{children:leftIcon}):leftIcon
                        }
                    />
                    {children &&
                    children.length &&
                    pathname.match(
                        new RegExp(
                            [resource.name, ...children.map(({ name }) => name)]
                                .map(nm => `^/${nm}`)
                                .join("|")
                        )
                    ) ? (
                        <div className="sub-menu">{buildMenu(children)}</div>
                    ) : null}
                </React.Fragment>
            );
        });

    return (
        <React.Fragment>
        <div
            className={`side-menu ${
                open ? "side-menu-open" : "side-menu-collapsed"
            }`}
        >

            {buildMenu(menuTree)}
            {fullAccess?<MenuItemLink
                exact
                leftIcon={menuIcon(RevisionIcon)}
                to="/"
                primaryText="Activity"
                {...menuItemProps}
            />:null}
            <MenuItemLink
                exact
                leftIcon={menuIcon(ManualIcon)}
                to={fullAccess?"/manual":"/"}
                primaryText="Manual"
                {...menuItemProps}
            />

            <Responsive
                small={logout}
                medium={null} // Pass null to render nothing on larger devices
            />
        </div>
      
        
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    resources: getResources(state)
});

export default withRouter(connect(mapStateToProps)(withWidth()(Menu)));
