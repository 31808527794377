import React from "react";

import {Edit} from "cms-toolbox/ReactAdminHoc"


import { FieldSet } from "cms-toolbox/FormContent";

import {contact as schema} from "taxi-schema"





import { TabbedFormHorizontal } from "cms-toolbox/FormHoc";
import { FormTab, EntityRevisionHistory, FormButtonsBottom } from "cms-toolbox";


export const ContactEdit = props => (
	<Edit title={"Contact page"} {...props} id={"contact"}>


  <TabbedFormHorizontal {...props} redirect={false}  warnWhenUnsavedChanges={false} toolbar={<FormButtonsBottom visitOnWebsite />}>
        <FormTab label="Edit">

		
		  <FieldSet
            schemaFragment={schema}
            
        />
			
				
		

		</FormTab>
         <EntityRevisionHistory {...props} />
         </TabbedFormHorizontal>
	</Edit>
);
