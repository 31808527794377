import React from "react";


import { Edit } from "cms-toolbox/ReactAdminHoc";

import { FieldSet } from "cms-toolbox/FormContent";

import { front as schema } from "taxi-schema";




import { TabbedFormHorizontal } from "cms-toolbox/FormHoc";
import { FormTab, EntityRevisionHistory, FormButtonsBottom } from "cms-toolbox";

export const FrontEdit = props => (
	<Edit title={"Front page"} {...props} id={"front"}>

    <TabbedFormHorizontal {...props} redirect={false}  warnWhenUnsavedChanges={false} toolbar={<FormButtonsBottom visitOnWebsite />}>
        <FormTab label="Edit" contentClassName="form-tabs-vertical">
          
               <FieldSet
            schemaFragment={schema}
            tab="main"
            tabLabel="Main"
        />

          <FieldSet schemaFragment={schema} tab="banner" tabLabel="Banner" />

			<FieldSet
				schemaFragment={schema}
				tab="usps"
				tabLabel="USPs"
			/>

		

			<FieldSet schemaFragment={schema} tab="text" tabLabel="Text" />

			<FieldSet schemaFragment={schema} tab="meta" tabLabel="Metadata" />
        </FormTab>
         <EntityRevisionHistory {...props} />
    </TabbedFormHorizontal>



	</Edit>
);
