import React from "react";

import { Edit } from "cms-toolbox/ReactAdminHoc";

import { FieldSet } from "cms-toolbox/FormContent";

import { globals as schema } from "taxi-schema";

import { TabbedFormHorizontal } from "cms-toolbox/FormHoc";
import { FormTab, EntityRevisionHistory } from "cms-toolbox";

export const SettingsEdit = props => (
    <Edit title={"Global settings"} {...props} id={"globals"}>
        <TabbedFormHorizontal
            {...props}
            redirect={false}
            warnWhenUnsavedChanges={false}
        >
            <FormTab label="Edit" contentClassName="form-tabs-vertical">
                <FieldSet
                    schemaFragment={schema}
                    tab="theme"
                    tabLabel="Theme elements"
                />
                <FieldSet
                    schemaFragment={schema}
                    tab="settings"
                    tabLabel="Constants"
                />
                <FieldSet
                    schemaFragment={schema}
                    tab="address"
                    tabLabel="Address"
                />
                 <FieldSet
                    schemaFragment={schema}
                    tab="regions"
                    tabLabel="Regions"
                />
               
            </FormTab>
            <EntityRevisionHistory {...props} />
        </TabbedFormHorizontal>
    </Edit>
);
